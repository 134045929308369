import camelcaseKeys from 'camelcase-keys';
import dayjs from 'dayjs';

export default class ListsItem {
  id: string;
  title: string;
  brandName: string;
  description: string;
  html_description: string;
  releaseAt: string;
  price: number;
  modelNumber: string;
  color: string;
  shopUrl: string;
  status: string;
  images: { url: string }[];
  originImages: { url: string }[];
  likeCount: number;
  haveCount: number;
  optionData?: {
    buttonLabel?: string;
    lotStartDate?: string;
    lotEndDate?: string;
  };
  releaseAtDisplay?: string;
  lotteryStartAt?: string;
  lotteryEndAt?: string;

  coverImage: string;
  noImage = false;

  // interfaceのみ？launch一覧でのみ使用
  isLaunch: boolean;

  // getter
  launched: boolean;
  releaseTimeString: string;
  buttonLabel: string;
  calendarTitle: string;
  calendarStartAt: Date;
  calendarEndAt: Date;
  canCalendar = true;
  lotteryTerm: string;

  constructor({
    id,
    title,
    brand_name,
    description,
    html_description,
    release_at,
    release_at_display,
    iine_count,
    have_count,
    price,
    model_number,
    color,
    shop_url,
    status,
    image,
    origin_images,
    option_data,
    isLaunch,
    coverImage,
    lottery_start_at,
    lottery_end_at,
  }: ItemDetailData) {
    this.id = id.toString();
    this.title = removeQuate(title);
    this.brandName = brand_name;
    this.description = removeQuate(description.replace(/"\r\n+/g, '"'));
    this.html_description = html_description;
    this.releaseAt = release_at;
    this.releaseAtDisplay = release_at_display;
    this.likeCount = iine_count;
    this.haveCount = have_count;
    this.price = price;
    this.modelNumber = model_number;
    this.color = color;
    this.shopUrl = shop_url;
    this.status = status;
    this.optionData = option_data && camelcaseKeys(option_data, { deep: true });
    this.haveCount = have_count;
    this.lotteryStartAt = lottery_start_at;
    this.lotteryEndAt = lottery_end_at;

    if (image.length === 0) {
      this.images = [{ url: '/static/images/lists_no_image.png' }];
      this.noImage = true;
    } else {
      this.images = image;
    }

    this.originImages = origin_images;

    this.coverImage =
      coverImage || origin_images?.[0]?.url || this.images[0].url;

    this.isLaunch = isLaunch;
    this.launched = dayjs(this.releaseAt) < dayjs();
    this.releaseTimeString = dayjs(this.releaseAt).format('H:mm');

    this.buttonLabel = (() => {
      if (this.launched) {
        return 'この商品を買う';
      }

      return this.optionData?.buttonLabel
        ? this.optionData.buttonLabel
        : `${this.releaseTimeString}に発売`;
    })();

    this.calendarTitle = this.optionData?.buttonLabel
      ? this.title + ' ' + this.optionData.buttonLabel
      : `${this.title} 発売予定`;

    this.calendarStartAt = (() => {
      if (this.optionData?.lotStartDate) {
        return dayjs(this.optionData.lotStartDate).toDate();
      }

      return dayjs(this.releaseAt).toDate();
    })();

    this.calendarEndAt = (() => {
      if (this.optionData?.lotStartDate) {
        return this.optionData.lotEndDate
          ? dayjs(this.optionData.lotEndDate).toDate()
          : dayjs(this.optionData.lotStartDate).add(3, 'hour').toDate();
      }

      return dayjs(this.releaseAt).add(3, 'hour').toDate();
    })();

    this.lotteryTerm = this.buildLotteryTerm();
  }

  private buildLotteryTerm() {
    const start = this.lotteryStartAt
      ? dayjs(this.lotteryStartAt).format('YYYY.MM.DD H:mm')
      : '';
    const end = this.lotteryEndAt
      ? ' ~ ' + dayjs(this.lotteryEndAt).format('YYYY.MM.DD H:mm')
      : '';

    return start + end;
  }
}

const removeQuate = (text?: string) => {
  if (!text) {
    return '';
  }

  return text && text.startsWith('"') ? text.slice(1, text.length - 1) : text;
};

// types
import type { ItemDetailData } from '@/infra/items/IItemRepository';

import createConfigs from '@/config/index';
import { getAllCtx, getByPagingCtx } from '@/infra/archives/archivesRepository';
import fetch from '@/infra/fetch/index';

const paths = {
  getAll: '/v1/pink_items/archive?es=true',
  getBypaging: '/v1/pink_items/load_next_items?scroll_id=',
};

export const getAll = getAllCtx.bind(getAllCtx, {
  fetch,
  url: new URL(paths.getAll, createConfigs().apiServer.origin).href,
});

export const getByPaging = getByPagingCtx.bind(getByPagingCtx, {
  fetch,
  url: new URL(paths.getBypaging, createConfigs().apiServer.origin).href,
});

import createConfigs from '@/config/index';
import _fetch from '@/infra/fetch/index';
import type {
  GetByIdProps,
  GetByIdResponse,
} from '@/infra/items/IItemRepository';
import {
  getByIdCtx,
  getBySearchTextCtx,
  getByShortcutCtx,
} from '@/infra/items/ItemRepository';

const paths = {
  getById: '/v1/pink_items/[id]',
  getBySearchText: '/v1/pink_items/search',
  getByShortcutBase: `v1/pink_items/`,
};

export const getById: (
  props: GetByIdProps
) => Promise<GetByIdResponse> = getByIdCtx.bind(getByIdCtx, {
  fetch: _fetch,
  url: new URL(paths.getById, createConfigs().apiServer.origin).href,
});

export const getBySearchText = getBySearchTextCtx.bind(getBySearchTextCtx, {
  fetch: _fetch,
  url: new URL(paths.getBySearchText, createConfigs().apiServer.origin).href,
});

export const getByShortcut = getByShortcutCtx.bind(getByShortcutCtx, {
  fetch: _fetch,
  url: new URL(paths.getByShortcutBase, createConfigs().apiServer.origin).href,
});

import { useOS } from '@/hooks/useOS';
import Header from '@/interface/ui/_shared/header/index';
import styles from '@/interface/ui/_shared/layout/index.module.scss';
import { useRouter } from 'next/router';

interface Props {
  children?: any;
  mode?: 'default' | 'pink';
  style?: any;
}

const Layout: React.FC<Props> = ({ children, mode, style }) => {
  const router = useRouter();
  const { isAtmosApp } = useOS();

  const isFocused = router?.query.data === '"autoFocus"' ? true : false;

  return (
    <div className={styles.container} style={style}>
      <Header focusedInput={isFocused} mode={mode} />
      <main className={isAtmosApp ? undefined : styles.main} style={style}>
        {children}
      </main>
    </div>
  );
};

export default Layout;

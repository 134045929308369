import createConfigs from '@/config/index';
import _fetch from '@/infra/fetch/index';
import type {
  GetByIdBoundDependencies,
  GetByIdProps,
  GetByIdResponse,
  GetBySearchTextBoundDependencies,
  GetBySearchTextProps,
  ItemRepositoryInputDTO,
  ItemRepositoryOutputDTO,
  ItemShortcutRepositoryInputDTO,
  ItemShortcutRepositoryOutputDTO,
} from '@/infra/items/IItemRepository';

const paths = {
  getById: '/v1/items/[id]',
  getBySearchText: '/v1/items/search',
  getByShortcutBase: `v1/items/`,
  getByLotteryShortcut: `v1/items/lottery_shortcut`,
};

export function getByIdCtx(
  { url, fetch }: GetByIdBoundDependencies,
  props: GetByIdProps
): Promise<GetByIdResponse> {
  const _url = url.replace('[id]', props.itemId.toString());
  return new Promise((resolve, reject) => {
    fetch(_url)
      .then((v) => {
        if (v.status === 401) {
          return reject(new Error('Unauthorized'));
        }

        if (v.status === 404) {
          return reject(new Error('NotFound'));
        }

        resolve(v.json());
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export const getById: (
  props: GetByIdProps
) => Promise<GetByIdResponse> = getByIdCtx.bind(getByIdCtx, {
  fetch: _fetch,
  url: new URL(paths.getById, createConfigs().apiServer.origin).href,
});

export async function getBySearchTextCtx(
  { url, fetch }: GetBySearchTextBoundDependencies,
  props: GetBySearchTextProps
): Promise<ItemRepositoryOutputDTO> {
  let _url = url;
  _url += `?search_text=${encodeURIComponent(props.searchText)}`;
  _url += `&language_code=${encodeURIComponent(props.languageCode)}`;
  _url += `&country_code=${encodeURIComponent(props.countryCode)}`;

  return new Promise((resolve, reject) => {
    fetch(_url)
      .then(async (v) => {
        const res = (await v.json()) as ItemRepositoryInputDTO;
        resolve({
          items: res.items,
          scrollId: res.scroll_id,
        });
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export const getBySearchText = getBySearchTextCtx.bind(getBySearchTextCtx, {
  fetch: _fetch,
  url: new URL(paths.getBySearchText, createConfigs().apiServer.origin).href,
});

export async function getByShortcutCtx(
  { url, fetch }: GetBySearchTextBoundDependencies,
  props: { type: 'launch' | 'archive'; shortcutId: number }
): Promise<ItemShortcutRepositoryOutputDTO> {
  const _url = url + `${props.type}_shortcut/?shortcut_id=${props.shortcutId}`;

  return new Promise((resolve, reject) => {
    fetch(_url)
      .then(async (v) => {
        const res = (await v.json()) as ItemShortcutRepositoryInputDTO;

        resolve({
          items: res.items,
          totalCount: res.total_count,
          eventIds: res.event_ids,
          scrollId: res.scroll_id,
          nextPage: res.next_page,
        });
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export const getByShortcut = getByShortcutCtx.bind(getByShortcutCtx, {
  fetch: _fetch,
  url: new URL(paths.getByShortcutBase, createConfigs().apiServer.origin).href,
});

export async function getByLotteryShortcutCtx({
  url,
  fetch,
}: GetBySearchTextBoundDependencies): Promise<ItemShortcutRepositoryOutputDTO> {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(async (v) => {
        const res = (await v.json()) as ItemShortcutRepositoryInputDTO;

        resolve({
          items: res.items,
          totalCount: res.total_count,
          scrollId: res.scroll_id,
          nextPage: res.next_page,
        });
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export const getByLotteryShortcut = getByLotteryShortcutCtx.bind(
  getByLotteryShortcutCtx,
  {
    fetch: _fetch,
    url: new URL(paths.getByLotteryShortcut, createConfigs().apiServer.origin)
      .href,
  }
);

import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

export const useOS = () => {
  const router = useRouter();
  const isWindowContext = typeof window !== 'undefined';
  const [osName, setOS] = useState<string>('');
  const [isAtmosApp, setIsAtmosApp] = useState<boolean>(!!router.query?.app);
  const [isAtmosPinkApp, setIsAtmosPinkApp] = useState<boolean | undefined>(
    undefined
  );

  function checkOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    if (macosPlatforms.indexOf(platform) !== -1) {
      setOS('Mac OS');
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      setOS('iOS');
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      setOS('Windows');
    } else if (/Android/.test(userAgent)) {
      setOS('Android');
    } else if (!osName && /Linux/.test(platform)) {
      setOS('Linux');
    }
  }

  const checkIfAtmosApp = () => {
    const userAgent = window.navigator.userAgent;
    const isApp =
      userAgent.includes('atmos-app') ||
      !!router.query?.app ||
      !!router.query?.atmos_app;
    setIsAtmosApp(isApp);
  };

  const checkIfAtmosPinkApp = () => {
    const userAgent = window.navigator.userAgent;
    const isApp =
      userAgent.includes('atmos-app') && !userAgent.includes('atmos-app-new');

    setIsAtmosPinkApp(isApp);
  };

  useEffect(() => {
    if (isWindowContext) {
      checkOS();
      checkIfAtmosApp();
      checkIfAtmosPinkApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWindowContext]);

  return { osName, isAtmosApp, isAtmosPinkApp };
};

import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useRef,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import Search from '@/interface/ui/_shared/footer/icons/search';
import styles from '@/interface/ui/search/_component/KeywordSearch.module.scss';
import { useRouter } from 'next/router';

interface Props {
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  clearText?: () => void;
  setTextValue: Dispatch<SetStateAction<string>>;
  searchingText?: string;
  isAutoFocus?: boolean;
  searchText?: string;
}

const KeywordSearch: React.FC<Props> = ({
  onBlur,
  onFocus,
  isAutoFocus,
  clearText,
  searchingText,
  searchText,
  setTextValue,
}) => {
  const router = useRouter();
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const _handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  const _clearText = useCallback(() => {
    if (!searchingText && router.pathname.endsWith('search')) {
      router.back();
      return;
    }

    clearText && clearText();
  }, [searchingText, clearText, router]);

  return (
    <div>
      <label className={styles.keywordSearchLabel}>
        <div className={styles.keywordSearchGlassIcon}>
          <Search />
        </div>
        <input
          className={styles.keywordSearchInput}
          alt="検索キーワード入力フォーム"
          placeholder="キーワードを入力"
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={isAutoFocus}
          onKeyDown={_handleKeyDown}
          ref={inputRef}
          value={searchText}
          onChange={(e) => setTextValue(e.target.value)}
        />
        <div className={styles.keywordSearchResetIcon} onClick={_clearText}>
          ✖️
        </div>
      </label>
    </div>
  );
};

export default KeywordSearch;

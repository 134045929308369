import React, { useCallback } from 'react';
import Link from 'next/link';
import { LinkWithi18n } from '@/infra/link/withi18n';
import { useSelector } from 'react-redux';
import styles from '@/interface/ui/_shared/header/index.module.scss';
import { routes } from '@/config/routes';
import KeywordSearch from '@/interface/ui/search/_component/KeywordSearch';
import { useOS } from '@/hooks/useOS';
import { shopHomeUrl } from '@/constants/app';

// types
import type { RootState } from '@/store/index';
import { useItemSearch } from '@/hooks/useItemSearch';

type LiProps = {
  keyName: string;
};

type HeaderProps = {
  focusedInput?: boolean;
  mode?: 'default' | 'pink';
};

const Li: React.FC<LiProps> = ({ keyName, children }) => {
  return (
    <li className={styles.li} key={keyName}>
      {children}
    </li>
  );
};

const Header: React.FC<HeaderProps> = ({ focusedInput, mode = 'default' }) => {
  const { isAtmosApp } = useOS();

  const { country, language } = useSelector((state: RootState) => state.app);

  const isModePink = mode === 'pink';
  const {
    searchText,
    searchingText,
    setSearchText,
    execSearch,
    reset,
  } = useItemSearch({
    isModePink,
  });

  const onBlurSearchTextInput = useCallback(async () => {
    await execSearch(searchText);
  }, [execSearch, searchText]);

  const renderKeywordSearch = useCallback(() => {
    return (
      <div className={styles.search}>
        <LinkWithi18n
          to={isModePink ? routes.pinkSearch : routes.search}
          country={country}
          language={language}
          isAutoFocus={'autoFocus'}
        >
          <KeywordSearch
            onBlur={onBlurSearchTextInput}
            isAutoFocus={focusedInput}
            searchText={searchText}
            clearText={reset}
            searchingText={searchingText}
            setTextValue={setSearchText}
          />
        </LinkWithi18n>
      </div>
    );
  }, [
    country,
    focusedInput,
    isModePink,
    language,
    onBlurSearchTextInput,
    reset,
    searchText,
    searchingText,
    setSearchText,
  ]);

  if (isAtmosApp) {
    return null;
  }

  return (
    <header>
      <nav className={isModePink ? styles['nav-pink'] : styles.nav}>
        <ul className={styles.ul}>
          <Li keyName="launch">
            <div className={styles.leftSide} />
            <div className={styles.center}>
              <Link href={shopHomeUrl[mode]}>
                <a className={styles.a}>
                  <img
                    src="/static/images/atmos_big_logo@2x.png"
                    alt="atmos"
                    className={styles.logo}
                  />
                </a>
              </Link>
            </div>
            <div className={styles.rightSide}>{renderKeywordSearch()}</div>
          </Li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

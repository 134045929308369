interface Route {
  as: string;
  href: string;
}

export const routes = {
  index: {
    as: '/launch',
  },
  launch: {
    as: '/launch',
  },
  archives: {
    as: '/archives',
  },
  detail(id: string): Route {
    return {
      href: '/t/[id]',
      as: `/t/${id}`,
    };
  },
  events: {
    detail(id: string): Route {
      return {
        href: '/events/[id]',
        as: `/events/${id}`,
      };
    },
  },
  messages: {
    index: {
      as: `/messages`,
    },
    id(id: string): Route {
      return {
        href: '/messages/[uid]',
        as: `/messages/${id}`,
      };
    },
  },
  laco: {
    as: '[country]/[language]',
  },
  search: {
    as: '/search',
  },
  pinkSearch: {
    as: '/pink/search',
  },
  notifications: {
    as: '/notifications',
  },
  auth: {
    signup: {
      as: '/auth/signup',
    },
    signin: {
      as: '/auth/login',
    },
  },
  users: {
    detail(id: number): Route {
      return {
        href: '/users/[id]',
        as: `/users/${id}`,
      };
    },
  },
  posts: {
    detail(id: number): Route {
      return {
        href: '/posts/[id]',
        as: `/posts/${id}`,
      };
    },
  },
};

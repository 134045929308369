import {
  archivesGetByPaging,
  Ctx,
  Props,
} from '@/app/archives/archivesGetByPaging';
import { getByPaging } from '@/infra/pink/archives/archivesRepository';
import EventEmitter from 'eventemitter3';

export default archivesGetByPaging.bind<
  typeof archivesGetByPaging,
  Ctx,
  [Props],
  EventEmitter
>(archivesGetByPaging, { getByPaging });

import { join } from 'path';
import Link from 'next/link';
import { routes } from '@/config/routes';

// types
interface To {
  as: string;
  href?: string;
}
interface MyProps {
  country: string;
  language: string;
  to: To;
  onClick?: () => void;
  isAutoFocus?: string;
  mode?: 'default' | 'pink';
}
import type { FunctionComponent } from 'react';

export const LinkWithi18n: FunctionComponent<MyProps> = ({
  children,
  country,
  language,
  to,
  onClick,
  isAutoFocus,
  mode,
}) => {
  const modePath = mode === 'pink' ? 'pink' : '';

  const as = join('/', country, language, modePath, to.as);
  const href = join('/', routes.laco.as, modePath, to.href ? to.href : to.as);

  return (
    <Link
      href={{
        pathname: href,
        query: { data: JSON.stringify(isAutoFocus) },
      }}
      as={as}
    >
      <div style={{ cursor: 'pointer' }} onClick={onClick}>
        {children}
      </div>
    </Link>
  );
};

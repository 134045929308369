const createConfigs = (): Configs => {
  if (!process.env.NEXT_PUBLIC_ATMOS_API_DOMAIN) {
    throw new Error('env `ATMOS_API_DOMAIN` must be set.');
  }
  return {
    apiServer: {
      origin: process.env.NEXT_PUBLIC_ATMOS_API_DOMAIN,
    },
  };
};

export default createConfigs;

// types
interface Configs {
  apiServer: ApiServer;
}
interface ApiServer {
  origin: string;
}

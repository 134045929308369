import Archive from '@/domain/archives/index';
import { getByPaging } from '@/infra/archives/archivesRepository';
import EventEmitter from 'eventemitter3';

export function archivesGetByPaging(
  { getByPaging }: Ctx,
  props?: Props
): EventEmitter {
  const ee = new EventEmitter();
  getByPaging({ scrollId: props?.scrollId })
    .then((res) => {
      const data: ResolvedData = res.items.map((chunk) => new Archive(chunk));
      ee.emit('SUCCESS', { items: data, scrollId: res.scrollId });
    })
    .catch((error) => {
      if (error.message === 'ServerError') {
        ee.emit('SERVER_ERROR', error);
        return;
      }
      ee.emit('ERROR', error);
    });
  return ee;
}

export default archivesGetByPaging.bind<
  typeof archivesGetByPaging,
  Ctx,
  [Props],
  EventEmitter
>(archivesGetByPaging, { getByPaging });
export type ResolvedData = Archive[];

export type GetByPaging = typeof getByPaging;
export interface Ctx {
  getByPaging: GetByPaging;
}
export interface Props {
  scrollId: string;
}

// export const storeMarket = {
//   appleStoreUrl: 'https://apps.apple.com/jp/app/atmos-app/id1537061292',
//   googlePlayUrl:
//     'https://play.google.com/store/apps/details?id=com.atmos_tokyo.app2',
// };

export const storeMarket = {
  appleStoreUrl: 'https://www.atmos-tokyo.com/lp/atmos-app-new',
  googlePlayUrl: 'https://www.atmos-tokyo.com/lp/atmos-app-new',
};

export const shopHomeUrl = {
  default: 'https://www.atmos-tokyo.com/',
  pink: 'https://www.atmos-pink.com/',
};

import EventEmitter from 'eventemitter3';
import { getBySearchText } from '@/infra/items/ItemRepository';
import Archive from '@/domain/archives/index';

interface Props {
  searchText: string;
  languageCode: string;
  countryCode: string;
}
export function itemsGetBySearchText(
  { getBySearchText },
  props: Props
): IEventEmitter {
  const ee = new EventEmitter();
  getBySearchText(props)
    .then((res) => {
      const items = res.items.map((item) => new Archive(item));
      ee.emit('SUCCESS', { items, scrollId: res.scrollId });
    })
    .catch((error) => {
      if (error.message === 'ServerError') {
        ee.emit('SERVER_ERROR', error);
        return;
      }
      ee.emit('ERROR', error);
    });
  return ee;
}

export default itemsGetBySearchText.bind(itemsGetBySearchText, {
  getBySearchText,
});
export type ResolvedData = Archive[];

import type IEventEmitter from 'eventemitter3';

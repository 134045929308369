import createConfigs from '@/config/index';
import fetch from '@/infra/fetch/index';

interface base {
  url: string;
  fetch: typeof fetch;
}

const paths = {
  getAll: '/v1/items/archive',
  getBypaging: '/v1/items/load_next_items?scroll_id=',
};

export function getAllCtx({ url, fetch }: base) {
  return fetch(url)
    .then(async (v) => {
      const res = await v.json();
      return { items: res.items, scrollId: res.scroll_id };
    })
    .catch((err) => {
      throw err;
    });
}

export function getByPagingCtx({ url, fetch }: base, { scrollId }) {
  return fetch(`${url}${scrollId}`)
    .then(async (v) => {
      const res = await v.json();
      return { items: res.items, scrollId: res.scroll_id };
    })
    .catch((err) => {
      throw err;
    });
}

export const getAll = getAllCtx.bind(getAllCtx, {
  fetch,
  url: new URL(paths.getAll, createConfigs().apiServer.origin).href,
});

export const getByPaging = getByPagingCtx.bind(getByPagingCtx, {
  fetch,
  url: new URL(paths.getBypaging, createConfigs().apiServer.origin).href,
});

import { useState, useCallback, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { executeUsercase } from '@/app/executeUsercase';
import itemsGetBySearchText, {
  ResolvedData,
} from '@/app/items/itemsGetBySearchText';
import pinkItemsGetBySearchText from '@/app/pink/items/itemsGetBySearchText';
import {
  searchItemsLoadSucceeded,
  searchItemsResetSucceeded,
} from '@/store/ducks/domain/searchItems';
import { RootState } from '@/store';
import { searching } from '@/store/ducks/domain/search';
import { GetBySearchTextProps } from '@/infra/items/IItemRepository';
import Archive from '@/domain/archives';

import archivesGetByPaging from '@/app/archives/archivesGetByPaging';
import pinkArchivesGetByPaging from '@/app/pink/archives/archivesGetByPaging';

export const useItemSearch = (options: {
  initialSearchText?: string;
  isModePink?: boolean;
}) => {
  const dispatch = useDispatch();
  const { country, language } = useSelector((state: RootState) => state.app);
  const searchItems = useSelector(
    (state: RootState) => state.domain.searchItems
  );
  const searchingText = useSelector(
    (state: RootState) => state.domain.search.searchText
  );

  const [searchText, setSearchText] = useState(
    options.initialSearchText || searchingText || ''
  );

  const execSearch = useCallback(
    async (text: string) => {
      if (text === searchingText) return;

      if (text.length) {
        const usecase = options.isModePink
          ? pinkItemsGetBySearchText
          : itemsGetBySearchText;

        dispatch(searchItemsResetSucceeded());
        const data = await executeUsercase<ResolvedData, GetBySearchTextProps>(
          usecase,
          {
            searchText: text,
            countryCode: country,
            languageCode: language,
          }
        );
        dispatch(searchItemsLoadSucceeded(data));
        setSearchText(text);
        dispatch(searching({ searchText: text }));
      } else {
        setSearchText('');
        dispatch(searching({ searchText: '' }));
      }
    },
    [country, dispatch, language, options.isModePink, searchingText]
  );

  const paging = useCallback(
    async (scrollId: string) => {
      const usecase = options.isModePink
        ? pinkArchivesGetByPaging
        : archivesGetByPaging;

      const data = await executeUsercase<
        ResolvedData,
        {
          scrollId: string;
        }
      >(usecase, {
        scrollId,
      });

      dispatch(searchItemsLoadSucceeded(data));
    },
    [dispatch, options.isModePink]
  );

  const reset = useCallback(() => {
    setSearchText('');
    dispatch(searchItemsResetSucceeded());
    dispatch(searching({ searchText: '' }));
  }, [dispatch]);

  const items: Array<Archive> = useMemo(() => {
    if (!searchItems) return [];

    const dumbArray: Array<Archive> = [];
    for (const id of searchItems.allIds) {
      const searchItem: Archive = searchItems.byId[`${id}`];
      dumbArray.push(searchItem);
    }
    return dumbArray;
  }, [searchItems]);

  const scrollId = searchItems.scrollId || null;
  const hasNoMoreData = searchItems?.hasNoMoreData || null;

  return {
    items,
    searchText,
    setSearchText,
    execSearch,
    reset,
    paging,
    searchingText,
    hasNoMoreData,
    scrollId,
  };
};

export async function executeUsercase<T, P = {}>(
  usecase: (prop: P) => EventEmitter,
  prop: P
): Promise<T> {
  return new Promise((resolve, reject) => {
    let ee: EventEmitter = usecase(prop);
    const onSuccess = (data) => {
      resolve(data);
      cleanUp();
    };
    const onError = (error) => {
      reject({
        type: 'ServerError',
        details: error.message,
      });
      cleanUp();
    };
    const cleanUp = () => {
      ee.removeAllListeners();
    };
    ee.once('SUCCESS', onSuccess)
      .once('ERROR', onError)
      .once('SERVER_ERROR', onError);
  });
}

// types
import type EventEmitter from 'eventemitter3';
